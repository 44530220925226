import React, { useState, useEffect } from 'react';
import './AppBar.css';
import { useNavigate } from 'react-router-dom';
import logofull from '../../media/images/logofull.png';
import API_UTIL from '../../services/AuthIntereptor';
import { Modal, Box, TextField, Button, Typography, Autocomplete } from '@mui/material';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';


const AppBar = ({ showLogout = true }) => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');


  const popularDomains = ['@gmail.com', '@yahoo.com', '@outlook.com', '@hotmail.com', '@aol.com'];


  useEffect(() => {
    const fetchUserDetails = async () => {
      const userPhoneNumber = localStorage.getItem('userPhoneNumber');
      if (!userPhoneNumber) {
        navigate('/login');
        return;
      }

      try {
        const response = await API_UTIL.get(`/fetchUserDetails/${userPhoneNumber}`);
        const userData = response.data.data;
        setUserDetails(userData);

        // If email is missing, open the modal
        if (!userData.email) {
          setEmailModalOpen(true);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        toast.error('Failed to load user data');
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email || !validateEmail(email)) {
      setEmailError('Please enter a valid email address (e.g., user@example.com)');
      return;
    }

    const userPhoneNumber = localStorage.getItem('userPhoneNumber');

    try {
      const response = await API_UTIL.post('/updateUserEmail', {
        userPhoneNumber,
        email,
      });

      if (response.status === 200) {
        setUserDetails({ ...userDetails, email });
        setEmailModalOpen(false);
        toast.success('Email updated successfully');
      }
    } catch (error) {
      console.error('Error updating email:', error);
      toast.error('Failed to update email');
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event, newValue, reason) => {
    // Only update the email if the change is from user input (not suggestion selection)
    if (reason === 'input') {
      const newEmail = event.target.value;
      setEmail(newEmail);
      if (newEmail && !validateEmail(newEmail)) {
        setEmailError('Please enter a valid email address (e.g., user@example.com)');
      } else {
        setEmailError('');
      }
    } else if (reason === 'select-option') {
      // When a suggestion is selected, update the email with the full value
      setEmail(newValue);
      setEmailError('');
    }
  };


  const emailOptions = popularDomains.map(domain => {
    const username = email.split('@')[0] || '';
    return email.includes('@') ? '' : `${username}${domain}`;
  }).filter(option => option);

  const handleLogout = () => {
    // Remove session storage
   localStorage.clear();

    // Navigate to the default page '/'
    navigate('/');
  };
  return (
    <>
    <div className='app-bar'>
      <div className='app-bar-logo'>
      <a 
          href="https://www.imageshield.com" 
          target="_blank" 
          rel="noopener noreferrer"
          className="logo-link"
        >
          <img
              src={logofull}
              alt="ImageShield Logo"
              style={{marginBottom: '0rem'}}
          />
          </a>
      </div>
    </div>
 <Modal
        open={emailModalOpen}
        onClose={() => setEmailModalOpen(false)}
        aria-labelledby="email-modal-title"
        aria-describedby="email-modal-description"
        className="modal-overlay"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="modal-content"
        >
          <Box p={3}>
            <Typography variant="h6" gutterBottom id="email-modal-title">
              Stay Updated
            </Typography>
            <Typography variant="body2" gutterBottom id="email-modal-description">
              Share your email to receive your photos if your phone number isn’t supported.
            </Typography>
            <form onSubmit={handleEmailSubmit}>
              <Autocomplete
                freeSolo
                options={emailOptions}
                value={email}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setEmail(newValue);
                    setEmailError(''); // Clear error on selection
                  }
                }}
                onInputChange={handleEmailChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Email here"
                    type="email"
                    fullWidth
                    required
                    error={!!emailError}
                    helperText={emailError}
                    sx={{ mb: 2 }}
                  />
                )}
                getOptionLabel={(option) => option || email}
                filterOptions={(options, { inputValue }) => {
                  if (!inputValue || inputValue.includes('@')) {
                    return []; // No suggestions if email contains '@'
                  }
                  return options.filter(option => 
                    option.toLowerCase().includes(inputValue.toLowerCase())
                  );
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!!emailError}
                sx={{
                  backgroundColor: '#1fb8f9',
                  '&:hover': { backgroundColor: '#0000ff' },
                }}
              >
                SUBMIT
              </Button>
            </form>
          </Box>
        </motion.div>
      </Modal>
    </>
  )
}

export default AppBar;
